// qrcode二维码自定义
<template>
  <div>
<!--    <el-icon v-show="!text" class="is-loading" size="50" color="#90909a" :style="{position:'absolute',}">-->
<!--      <Loading/>-->
<!--    </el-icon>-->
    <img  ref="qrCodeIco"
         :src="src==''?require(`@/assets/images/buy-logo.png`):require(`@/assets/images/${src}.png`)"
         alt="">
    <div  id="qrCode" ref="qrCodeDivs"></div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';

export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    },
    colorDark: {
      type: String,
      default: '#333333'
    },
    colorLight: {
      type: String,
      default: '#ffffff'
    },
    correctLevel: {
      type: String,
      default: 'H'
    },
    src: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 30
    },
  },
  data() {
    return {
      imgSize: this.size
    }
  },
  computed: {},
  watch: {
    src(news, old) {
      // console.log('bindQRCode watch src', news, old)
      document.getElementById('qrCode').innerHTML = ""
      this.bindQRCode()
    },
    text(news, old) {
      // console.log('bindQRCode watch text', news, old)
      document.getElementById('qrCode').innerHTML = ""
      this.bindQRCode()
    },
  },
  mounted() {
    this.bindQRCode()
  },
  methods: {
    bindQRCode() {
      // console.log('bindQRCode', this.src, this.text)
      this.src == '' ? this.imgSize = 30 : this.imgSize = this.size;
      this.$refs.qrCodeIco.style.position = 'absolute'
      this.$refs.qrCodeIco.style.width = this.imgSize + 'px'
      this.$refs.qrCodeIco.style.height = this.imgSize + 'px'
      new QRCode(this.$refs.qrCodeDivs, {
        text: this.text,
        width: this.width,
        height: this.height,
        colorDark: this.colorDark, //二维码颜色
        colorLight: this.colorLight, //二维码背景色
        correctLevel: QRCode.CorrectLevel[this.correctLevel]//容错率，L/M/H 7%/15%/30%
      })
      let margin = (this.height - this.imgSize) / 2;
      this.$refs.qrCodeIco.style.margin = margin + 'px'
    },
  }
}
</script>

<style scoped>

</style>
